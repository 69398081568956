
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    /* font-weight: 400; */
    /* font-style: normal; */
}

@font-face {
    font-family: 'SeoulHangangLight';
    font-weight: lighter;
    src: url('./assets/font/SeoulHangangLight.ttf') format('truetype');
}

@font-face {
    font-family: 'SeoulHangangRegular';
    font-weight: normal;
    src: url('./assets/font/SeoulHangangRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'SeoulHangangBold';
    font-weight: normal;
    src: url('./assets/font/SeoulHangangBold.ttf') format('truetype');
} 

body {
    font-family: 'Pretendard-Regular', sans-serif;
    font-weight: normal;
}